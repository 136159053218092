.contentImage {
    .content {
        position: relative;
        z-index: 5;
    }

    .tagline {
        left: 75.8%;
    }

    .shape {
        defs {
            stop:first-child {
                stop-color: $orange; // Orange
            }
            stop:last-child {
                stop-color: #cf8216; // Yellow
            }
        }
    }

    .shape-container {
        position: absolute;
        right: 0;
        top: 30%;
        z-index: 0;
    }

    .image-container {
        height: 645px;
    }
}
