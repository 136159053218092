$primary: #1b9ea0;
$light: #f1f1f4;
$dark: #0a0c1d;
$orange: #f8a31e;
$greens: #83bc45;
$purple: #2c317c;
$body-color: #858585;

$font-family-base: "Inter", sans-serif;

$font-size-base: 1.125rem; // 16px
$h1-font-size: $font-size-base * 4.056; // 73px
$h2-font-size: $font-size-base * 3.056; // 55px
$h3-font-size: $font-size-base * 1.167; // 21px
$h4-font-size: $font-size-base * 1.278; // 23px
$h5-font-size: $font-size-base * 1.563; // 25px
$h6-font-size: $font-size-base * 1.25; // 20px

$font-sizes: (
    1: $h1-font-size,
    2: $h2-font-size,
    3: $h3-font-size,
    4: $h4-font-size,
    5: $h5-font-size,
    6: $h6-font-size
);

$lead-font-size: $font-size-base * 1.111;
$lead-font-weight: 400;

$btn-padding-y: 2rem;
$btn-padding-x: 2rem;
$btn-font-size: $font-size-base * 0.889;
$btn-border-radius: 0;
$btn-font-weight: 700;
$btn-box-shadow: none;

$spacer: 1rem;
$spacers: (
    0: 0,
    1: $spacer * 0.25,
    2: $spacer * 0.5,
    3: $spacer,
    4: $spacer * 1.5,
    5: $spacer * 3,
    6: $spacer * 4.5,
    7: $spacer * 6,
    8: $spacer * 7.5,
    9: $spacer * 9
);
