.imageContent {
    .content {
        position: relative;
        z-index: 5;
    }

    .tagline {
        left: -18vw;
        top: 15%;

        @include media-breakpoint-up(md) {
            left: -16vw;
            top: 17%;
        }

        @include media-breakpoint-up(lg) {
            left: 6.6vw;
            top: 0;
        }

        @include media-breakpoint-up(xxl) {
            left: 8.7vw;
        }
    }

    .shape {
        defs {
            stop:first-child {
                stop-color: $primary; // Orange
            }
            stop:last-child {
                stop-color: #1fc2c5; // Yellow
            }
        }
    }

    .shape-container {
        display: block;
        position: absolute;
        left: 0;
        top: 30%;
        z-index: 0;

        @include media-breakpoint-down(lg) {
            display: none;
        }
    }

    .image-container {
        height: 445px;

        @include media-breakpoint-up(lg) {
            height: 645px;
        }
    }
}
