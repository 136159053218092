.hero {
    overflow: hidden;
    position: relative;

    @include media-breakpoint-up(xl) {
        min-height: calc(110vh + 3rem);
    }

    p {
        @include media-breakpoint-down(xl) {
            color: $white !important;
        }
    }

    .text-dark {
        @include media-breakpoint-down(xl) {
            color: $white !important;
        }
    }

    .content {
        position: relative;

        @include media-breakpoint-up(xl) {
            position: absolute;
            top: 50%;
            left: 0;
            transform: translate(0, -50%);
            z-index: 1;
        }
    }

    .hero-image {
        margin: 50px 0 0 0 !important;
        height: 100%;
        width: 100%;
        position: absolute;
        top: 0;
        right: 0;
        z-index: -1;

        @include media-breakpoint-up(xl) {
            mask-image: url(https://www.echoexp.com/uploads/images/echoIcon.svg);
            mask-repeat: no-repeat;
            mask-size: cover;
            mask-position: center;
            position: relative;
            mask-position: bottom left;
            height: 110vh;
            width: 75vw;
            top: 3rem;
            right: -42vw;
            margin: 0 !important;
        }
    }

    .video-container {
        position: relative;
        width: 100%; /* Adjust the width as necessary */
        height: 100%; /* Adjust the height as necessary */
        overflow: hidden;

        @include media-breakpoint-down(xl) {
            background: #000;
        }
    }

    video {
        width: 100%;
        height: 100%;
        object-fit: cover; /* This will cover the area of the container */

        @include media-breakpoint-down(xl) {
            opacity: 0.4;
        }
    }

    .tagline {
        position: relative;
        top: 0;
        left: 0;
        transform: none;

        &::after {
            display: none;
        }
    }
}
