header {
    .siteLogo {
        height: 40px;
        width: auto;
        transition: height 0.3s;

        @include media-breakpoint-up(lg) {
            height: 58px;
        }
    }

    .nav-link {
        color: #696a75;

        @include media-breakpoint-up(lg) {
            padding-right: 2rem !important;
            padding-left: 2rem !important;
        }
    }
}

.navbar-brand {
    display: inline-block;
    overflow: hidden;
}

.navbar {
    width: 100%;
    background: rgba(255, 255, 255, 1);
    transition: top background 0.3s; /* Smooth transition for the sticking effect */

    @include media-breakpoint-up(xxl) {
        background: rgba(255, 255, 255, 0);
    }

    .btn {
        transition: padding 0.3s;
    }
}

.sticky {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    background: rgba(255, 255, 255, 1);

    .btn {
        padding: 1rem 2rem;
    }

    .siteLogo {
        height: 35px;

        @include media-breakpoint-up(lg) {
            height: 40px;
        }
    }
}
