.lightbox {
  img:not(.lightbox-disabled) {
    cursor: zoom-in;
  }
}

.lightbox-gallery {
  visibility: hidden;
  position: fixed;
  left: 0 #{'/*!rtl:ignore*/'};
  top: 0;
  width: 100%;
  height: 100%;
  z-index: $zindex-lightbox;
  background-color: $lightbox-gallery-background-color;
  opacity: 0;
  pointer-events: none;
  transition: $lightbox-gallery-transition;
  .lightbox-gallery-toolbar {
    position: absolute;
    top: 0;
    left: 0 #{'/*!rtl:ignore*/'};
    width: 100%;
    height: $lightbox-gallery-toolbar-height;
    z-index: $zindex-lightbox-toolbar;
    transition: $lightbox-gallery-toolbar-transition;
    .lightbox-gallery-left-tools {
      float: left;
      height: 100%;
    }
    .lightbox-gallery-right-tools {
      float: right;
    }
    button {
      border: none;
      background: none;
      width: $lightbox-gallery-toolbar-button-width;
      height: $lightbox-gallery-toolbar-button-height;
      color: $lightbox-gallery-toolbar-button-color;
      transition: $lightbox-gallery-toolbar-button-transition;
      &:hover {
        color: $lightbox-gallery-toolbar-button-hover-color;
      }
      &:before {
        font-family: 'Font Awesome 6 Free';
        font-weight: $lightbox-gallery-toolbar-button-before-font-weight;
      }
      &.fontawesome-pro:before {
        font-family: 'Font Awesome 6 Pro';
      }
      &.lightbox-gallery-fullscreen-btn:before {
        content: '\f065';
      }
      &.lightbox-gallery-fullscreen-btn.active:before {
        content: '\f066';
      }
      &.lightbox-gallery-zoom-btn:before {
        content: '\f00e';
      }
      &.lightbox-gallery-zoom-btn.active:before {
        content: '\f010';
      }
      &.lightbox-gallery-close-btn:before {
        content: '\f00d';
      }
    }
  }

  .lightbox-gallery-counter {
    height: 100%;
    color: $lightbox-gallery-counter-color;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 $lightbox-gallery-counter-padding-x;
    margin-bottom: 0;
    direction: ltr #{'/*!rtl:ignore*/'};
  }

  .lightbox-gallery-content {
    position: fixed;
    top: $lightbox-gallery-content-top;
    left: $lightbox-gallery-content-left #{'/*!rtl:ignore*/'};
    width: $lightbox-gallery-content-width;
    height: $lightbox-gallery-content-height;
    .lightbox-gallery-image {
      position: absolute;
      left: 0 #{'/*!rtl:ignore*/'};
      top: 0;
      width: 100%;
      height: 100%;
      opacity: 0;
      transform: $lightbox-gallery-image-transform;
      transition: $lightbox-gallery-image-transition;
      pointer-events: none;
      img {
        position: absolute;
        left: 0 #{'/*!rtl:ignore*/'};
        top: 0;
        width: 100%;
        max-width: 100%;
        height: auto;
        cursor: pointer;
        pointer-events: auto;
        &.vertical {
          height: 100%;
          max-height: 100%;
          width: auto;
          max-width: initial;
        }
      }
    }
  }
  .lightbox-gallery-arrow-right {
    position: fixed;
    right: 0;
    top: 0;
    width: $lightbox-gallery-arrow-right-width;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: $lightbox-gallery-arrow-right-transition;
    button {
      border: none;
      background: none;
      width: $lightbox-gallery-arrow-right-button-width;
      height: $lightbox-gallery-arrow-right-button-height;
      color: $lightbox-gallery-arrow-right-button-color;
      transition: $lightbox-gallery-arrow-right-button-transition;
      &:hover {
        color: $lightbox-gallery-arrow-right-button-hover-color;
      }
      &:before {
        font-family: 'Font Awesome 6 Free';
        font-weight: $lightbox-gallery-arrow-right-button-before-font-weight;
        content: '\f061';
      }
      &.fontawesome-pro:before {
        font-family: 'Font Awesome 6 Pro';
      }
    }
  }
  .lightbox-gallery-arrow-left {
    position: fixed;
    left: 0;
    top: 0;
    width: $lightbox-gallery-arrow-left-width;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: $lightbox-gallery-arrow-left-transition;
    button {
      border: none;
      background: none;
      width: $lightbox-gallery-arrow-left-button-width;
      height: $lightbox-gallery-arrow-left-button-height;
      color: $lightbox-gallery-arrow-left-button-color;
      transition: $lightbox-gallery-arrow-left-button-transition;
      &:hover {
        color: $lightbox-gallery-arrow-left-button-hover-color;
      }
      &:before {
        font-family: 'Font Awesome 6 Free';
        font-weight: $lightbox-gallery-arrow-left-button-before-font-weight;
        content: '\f060';
      }
      &.fontawesome-pro:before {
        font-family: 'Font Awesome 6 Pro';
      }
    }
  }
  .lightbox-gallery-caption-wrapper {
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
    height: $lightbox-gallery-caption-wrapper-height;
    display: flex;
    justify-content: center;
    align-items: center;
    .lightbox-gallery-caption {
      color: $lightbox-gallery-caption-color;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      margin: 0 $lightbox-gallery-caption-margin-x;
      text-align: center;
    }
  }
  .lightbox-gallery-loader {
    position: fixed;
    left: 0;
    top: 0;
    z-index: 2;
    width: 100%;
    height: 100%;
    opacity: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    pointer-events: none;
    transition: $lightbox-gallery-loader-transition;
  }
  button:focus {
    outline: none;
    color: $lightbox-gallery-button-focus-color;
  }
}

.disabled-scroll {
  overflow-y: hidden;
  position: relative;
  &.replace-scrollbar {
    @media only screen and (min-device-width: 768px) {
      padding-right: $lightbox-disabled-scroll-media-padding-right;
    }
  }
}
