.autocomplete-label {
  max-width: 80%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  &.active {
    transform: translateY(-1rem) translateY(0.1rem) scale(0.8);
  }
}

.form-outline .autocomplete-label.active ~ .form-notch .form-notch-middle {
  border-top: none;
  border-right: none;
  border-left: none;
}

.select-input {
  cursor: pointer;

  &[disabled] {
    cursor: default;
  }
}

.autocomplete-input.focused {
  color: #616161;
  outline: 0;

  & ~ .autocomplete-label {
    color: #1266f1;
  }

  &::placeholder {
    opacity: 1;
  }

  & ~ .form-notch .form-notch-leading {
    border-color: $form-outline-select-notch-border-color;
    box-shadow: -1px 0 0 0 $form-outline-select-notch-border-color,
      0 1px 0 0 $form-outline-select-notch-border-color,
      0 -1px 0 0 $form-outline-select-notch-border-color;
  }

  & ~ .form-notch .form-notch-middle {
    border-color: $form-outline-select-notch-border-color;
    box-shadow: 0 1px 0 0 $form-outline-select-notch-border-color;
    border-top: 1px solid transparent;
  }

  & ~ .form-notch .form-notch-trailing {
    border-color: $form-outline-select-notch-border-color;
    box-shadow: 1px 0 0 0 $form-outline-select-notch-border-color,
      0 -1px 0 0 $form-outline-select-notch-border-color,
      0 1px 0 0 $form-outline-select-notch-border-color;
  }
}

.autocomplete-dropdown-container {
  z-index: $zindex-autocomplete-dropdown-container;
}

.autocomplete-dropdown {
  background-color: #fff;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
  margin: 0;
  outline: 0;
  position: relative;
  transform: scaleY(0.8);
  opacity: 0;
  transition: all 0.2s;

  &.open {
    transform: scaleY(1);
    opacity: 1;
  }
}

.autocomplete-items-list {
  list-style: none;
  margin: 0;
  padding: 0;

  overflow-y: auto;

  &::-webkit-scrollbar {
    width: 4px;
    height: 4px;
  }

  &::-webkit-scrollbar-button {
    &:start:decrement,
    &:end:increment {
      display: block;
      height: 0;
      background-color: transparent;
    }
  }

  &::-webkit-scrollbar-track-piece {
    background-color: transparent;
    border-radius: 0;
    border-bottom-right-radius: 4px;
    border-bottom-left-radius: 4px;
  }

  &::-webkit-scrollbar-thumb:vertical {
    height: 50px;
    background-color: #999;
    border-radius: 4px;
  }
}

.autocomplete-item {
  width: 100%;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  color: rgba(0, 0, 0, 0.87);
  padding: 6.5px 16px;
  font-size: 1rem;
  font-weight: 400;
  background-color: transparent;
  user-select: none;

  &:hover:not(.disabled) {
    background-color: #ddd;
  }

  &.active {
    background-color: #ddd;
  }

  &.disabled {
    cursor: default;
    color: #9e9e9e;
  }
}

.autocomplete-loader {
  position: absolute;
  right: 5px;
  top: 8px;
  width: 1.4rem;
  height: 1.4rem;
  border-width: 0.15em;
}

.form-outline .autocomplete-input.form-control.focusing ~ .form-notch .form-notch-middle {
  transition: none;
}
