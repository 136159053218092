h1,
.h1 {
    font-weight: 400;
    line-height: 1.1;
}

h2,
.h2 {
    font-weight: 400;
}

.lead {
    line-height: 1.5;
}

.lh-xs {
    line-height: 0.9;
}

.fa-3x {
    font-size: 40px;
}

.tagline {
    font-size: $font-size-base * 0.889;
    position: absolute;
    top: -6%;
    left: 35%;
    transform: rotate(-90deg);
    color: $dark;
    font-weight: 700;
    letter-spacing: 0.5px;
    text-transform: uppercase;

    &::after {
        content: "";
        display: block;
        height: 5px;
        width: 100%;
        position: relative;
        margin-top: 5px;
    }
}
