.accent-primary {
    .text-primary {
        color: $primary !important;
    }

    .btn-primary {
        background-color: $primary;
    }

    .tagline::after {
        background: $primary;
    }
}

.accent-green {
    .text-primary {
        color: $greens !important;
    }

    .btn-primary {
        background-color: $greens;
    }

    .tagline::after {
        background: $greens;
    }
}

.accent-orange {
    .text-primary {
        color: $orange !important;
    }

    .btn-primary {
        background-color: $orange;
    }

    .tagline::after {
        background: $orange;
    }
}

.page-49 {
    .navbar {
        .phone-container {
            a,
            svg {
                color: $orange !important;
            }
        }
    }

    .text-primary {
        color: $orange !important;
    }

    .btn-primary,
    .tagline::after,
    footer .bg-primary {
        background-color: $orange !important;
    }
}

.page-51 {
    .navbar {
        .phone-container {
            a,
            svg {
                color: $greens !important;
            }
        }
    }

    .text-primary {
        color: $greens !important;
    }

    .btn-primary,
    .tagline::after,
    footer .bg-primary {
        background-color: $greens !important;
    }
}
