.btn {
    @include button-size($btn-padding-y, $btn-padding-x, $btn-font-size, $btn-border-radius);

    @include media-breakpoint-down(md) {
        width: 100%;
        margin: 0.25rem auto;
    }
}

.fui-btn {
    border: none !important;
    @extend .btn;
    @extend .btn-primary;
    background-color: $primary !important;
    padding: 2rem !important;
    font-size: 1.000125rem !important;
    border-radius: 0 !important;
}
