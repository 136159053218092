.services {
    .tagline {
        top: 0;
        left: -10vw;

        @include media-breakpoint-up(lg) {
            top: 0;
            left: 19.7%;
        }
    }
}
