html,
body {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-rendering: geometricPrecision;
}
.z-index-1 {
    z-index: 1;
}
.z-index-2 {
    z-index: 2;
}
.z-index-3 {
    z-index: 3;
}
.z-index-4 {
    z-index: 4;
}
.z-index-5 {
    z-index: 5;
}

.lightbox-gallery {
    backdrop-filter: blur(15px);
    background-color: rgba(255, 255, 255, 0.6);
}

.btn {
    text-transform: capitalize;
}

.accentLines {
    top: 0;
    left: 0;
    z-index: -1;

    > * {
        position: absolute;
        top: 0;
        width: 1px;
    }
    .ac-1 {
        left: 9%;
    }
    .ac-2 {
        left: 25.6%;
    }
    .ac-3 {
        left: 51%;
    }
    .ac-4 {
        left: 70.23%;
    }
    .ac-5 {
        left: 91%;
    }
}

.icon-container {
    .icon {
        width: 50px;
    }
}

#devTools {
    position: fixed;
    bottom: 0;
    right: 0;
    z-index: 99999;
    background: #000;
    color: #fff;
    font-size: 12px;
    padding: 0.5em;
    border-top-left-radius: 0.25em;
}
