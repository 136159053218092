.galleryContent {
    .shape-container {
        position: absolute;
        left: 0;
        top: 0;
        z-index: -1;
        width: 49vw;
        height: auto;
        overflow: hidden;
        display: flex;
        justify-content: flex-start;

        @include media-breakpoint-down(lg) {
            display: none;
        }

        img {
            max-width: 100%;
            height: auto;
        }
    }

    .tagline {
        left: -26vw;
        top: 21%;

        @include media-breakpoint-up(md) {
            left: -21vw;
            top: 25%;
        }

        @include media-breakpoint-up(lg) {
            left: 34.2vw;
            top: -24%;
        }

        @include media-breakpoint-up(xl) {
            top: 0;
            left: 40.5%;
        }
    }

    .inner {
        @include media-breakpoint-up(lg) {
            //   transform: translate(-36%, 45%);
        }

        @include media-breakpoint-up(xl) {
            transform: translate(-36%, 45%);
        }

        @include media-breakpoint-up(xxl) {
            transform: translate(-25%, 25%);
        }
    }
}
