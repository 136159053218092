.caseStudies {
    .shape-container {
        position: absolute;
        top: 0;
        right: 0;
        z-index: -1;
        width: 30vw;
        height: auto;
        overflow: hidden;
        display: flex;
        justify-content: flex-end;

        @include media-breakpoint-down(lg) {
            display: none;
        }

        img {
            max-width: 100%;
            height: auto;
        }
    }

    .tagline {
        top: 19%;
        left: -27vw;

        @include media-breakpoint-up(md) {
            left: -22vw;
            top: 27%;
        }

        @include media-breakpoint-up(lg) {
            left: 14vw;
            top: -35%;
        }

        @include media-breakpoint-up(xl) {
            left: 7.6vw;
            top: -27%;
        }

        @include media-breakpoint-up(xxl) {
            top: -40%;
            left: 20vw;
        }
    }

    .card {
        height: 445px;

        @include media-breakpoint-up(lg) {
            height: 645px;
        }
    }
}
