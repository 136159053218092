body.page-53 {
    footer {
        .bg-accent {
            height: 100%;

            img {
                object-fit: cover;
                object-position: center;
                width: 100%;
                height: 100%;
                max-width: none !important;
            }
        }
    }
}

footer {
    a {
        color: $white !important;
    }

    .tagline {
        left: -4vw;
        top: 3%;

        @include media-breakpoint-up(lg) {
            left: 17.7vw;
            top: -10%;
        }

        @include media-breakpoint-up(xl) {
            top: 0;
            left: 21.4%;
        }
    }

    .newsletter {
        .fui-page {
            position: relative;
        }
        .fui-btn-wrapper {
            position: absolute;
            right: 4px;
            top: 4px;
            padding: 0;
            height: calc(100% - 8px);

            .fui-btn {
                padding: 0 1rem !important;
                height: 100%;
            }
        }
    }

    .bg-accent {
        position: absolute;
        bottom: 0;
        left: 0;
        z-index: 0;
        width: 100%;
        height: 50%;

        @include media-breakpoint-up(xl) {
            height: 50%;
        }

        @include media-breakpoint-up(xxl) {
            height: auto;
        }
    }
}

.page-53 {
    footer .bg-accent {
        @include media-breakpoint-down(lg) {
            height: 100%;
        }
    }
}
